/* Robert Leslie
  2021
  wheel decider
*/

/* basic styling */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --app-font: 'Quicksand', 'Lato', sans-serif;
  --neutral-color: white;
}

/* styling for app component */
.App {
  font-family: var(--app-font);
  background: var(--neutral-color);
  border: 3px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.2);
  /* padding: 2rem 0; */
  background-color: rgb(248, 247, 247);
  margin-bottom: 2rem;
  margin: 0 5px 5px 5px;
  /* color: #b94d00; */
}

@media (min-width:1200px){
  .App{
    margin-top: 1rem;
    flex-direction: row;
  }
}

/* for header on App component */
.heading{
  font-family: impact;
  font-size: 2.5em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow:2px 2px 0 #000,
  -2px -2px 0 #000,
  2px -2px 0 #000,
  -2px 2px 0 #000,
  0px 2px 0 #000,
  2px 0px 0 #000,
  0px -2px 0 #000,
  -2px 0px 0 #000,
  2px 2px 5px #000;
  border: 3px solid black;
  border-radius: 5px;
  background-color: #6593F5;
  text-align: center;
  margin: 1.75rem 5px;
}
